import React, { useState } from 'react';
import {
	Avatar,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Stack,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { USER_AVATAR } from '@helpers/api';
import { Link } from 'gatsby';
import { UserState } from '@store/user/types';
import { NavigationState } from '@store/navigation/types';
import styles from './styles';
import { useTheme } from '@mui/material';
import { useLocation } from '@reach/router';

const SideMenu: React.FC<{
	user: UserState;
	navigation?: NavigationState;
	showEmail?: boolean;
	inverted?: boolean;
}> = ({ user, navigation, showEmail, inverted }) => {
	const theme = useTheme();
	const [userDropdownOpen, setUserDropdownOpen] = useState<boolean>(false);
	const $avatar: any = React.useRef(null);
	const location = useLocation();
	const xs = useMediaQuery(theme.breakpoints.up('xs'));
	const md = useMediaQuery(theme.breakpoints.up('md'));
	const between = useMediaQuery(theme.breakpoints.between('md', 'lg'));

	// xs 0 - dont display name and email
	// sm 600px - grid flex direction row-reverse & stack align items flex-start
	// between sm and lg

	return (
		<div>
			<Stack
				direction="row"
				spacing={2}
				alignItems="center"
				flexDirection={between ? 'row-reverse' : 'row'}
			>
				{md && (
					<Stack
						direction="column"
						alignItems={`flex-${between ? 'start' : 'end'}`}
						sx={{ ml: between ? 2 : 0 }}
					>
						<Typography
							className={`menu-item ${
								(theme.palette.mode === 'light' &&
									location.pathname.includes('dashboard')) ||
								location.pathname.includes('pricing') ||
								location.pathname.includes('affiliate')
									? ''
									: 'inverted'
							}`}
							variant="body1"
							noWrap
							align="right"
						>
							<b>
								{user?.firstName} {user?.lastName}
							</b>
						</Typography>

						<Typography
							className={`menu-item ${
								theme.palette.mode === 'dark' ? 'inverted' : ''
							}`}
							variant="body2"
							noWrap
							align="right"
						>
							<span className="email">{user?.email}</span>
						</Typography>
					</Stack>
				)}
				<IconButton
					ref={$avatar}
					aria-controls={navigation?.open ? 'menu-list-grow' : undefined}
					aria-haspopup="true"
					onClick={() => setUserDropdownOpen(!userDropdownOpen)}
					className="avatar"
				>
					<Avatar
						className="avatar-image"
						alt={`${user?.firstName}'s avatar`}
						src={`${USER_AVATAR(user?._id)}`}
					/>
				</IconButton>
			</Stack>

			<Menu
				anchorEl={$avatar?.current}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				keepMounted
				open={userDropdownOpen}
				onClose={() => setUserDropdownOpen(false)}
				onClick={() => setUserDropdownOpen(false)}
			>
				<MenuItem component={Link} to="/dashboard/cards">
					Dashboard
				</MenuItem>
				<MenuItem component={Link} to="/dashboard/account">
					Edit Account
				</MenuItem>
				<MenuItem component={Link} to="/logout">
					Logout
				</MenuItem>
			</Menu>
		</div>
	);
};

export default SideMenu;
